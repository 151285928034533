/* Custom scrollbar styles inspired by Material-UI default theme */

.style-scrollbar::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.style-scrollbar::-webkit-scrollbar-track {
    background: #90caf9;
    border-radius: 8px;
}

.style-scrollbar::-webkit-scrollbar-thumb {
    background: #1976d2;
    border-radius: 8px;
    border: 0px solid #0d47a1;
}

.style-scrollbar::-webkit-scrollbar-thumb:hover {
    border: 2px solid #90caf9;
}

@supports not selector(::-webkit-scrollbar) {
    .style-scrollbar {
        scrollbar-color: #1976d2 #90caf9;
    }
}