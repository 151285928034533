.logo {
    display: inline;
    font-family: "Prompt", sans-serif;
    line-height: 1;
    font-weight: bold;
    color: black;
    user-select: none; 
}

.numberTwo {
    color: #007BFF; /* Example: bright blue, change as needed */
}